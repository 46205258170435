import React, { useEffect, useState, useCallback, useRef  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import axios from 'axios';
import '../styles/NftDetailsPage.css';


const NftDetailsPage = () => {
  const { isConnected } = useWallet();
  const navigate = useNavigate();
  const { collectionId, nftId } = useParams();
  const [nft, setNft] = useState(null);
  const [history, setHistory] = useState(null);
  const [nftOwner, setNftOwner] = useState(null);
  const { walletAddress } = useWallet();
  const ExchangeAddress = "klv1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqylllsymmgky";
  const [orderId, setOrderId] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [listingPrice, setListingPrice] = useState("");
  const [isPriceConfirmed, setIsPriceConfirmed] = useState(false);
  const [latestPrice, setLatestPrice] = useState(null);
  const { useKdaFee, selectedKda } = useWallet(); // Get KDA fee states

  



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [endDate, setEndDate] = useState(new Date().toISOString().substring(0, 10)); // Default to today's date
  const [endTimeUnix, setEndTimeUnix] = useState(null);

  const listNftForSale = useCallback(async () => {
    const payload = {
      marketType: 0, // Assuming 0 is the market type for a standard sale
      marketplaceId: "417b70c0eb7a33cb", // Your marketplace ID
      assetId: `${collectionId}/${nftId}`, // The asset ID of the NFT
      currencyId: "KLV", // The currency in which you want to list the NFT
      price: listingPrice * 10 ** 6, // Convert the price to the smallest unit
      endTime: endTimeUnix, // The end time in hexadecimal format
    };
  
    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }
  
    try {
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: 18, // Replace with the correct transaction type for selling an NFT
        },
      ], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Transaction response:', response);
  
      // Handle the UI updates and state changes as needed
      setTransactionStatus("Transaction completed!");  // Set the status
      // ... other UI updates
  
    } catch (error) {
      console.error('Error sending transaction:', error);
      setTransactionStatus("Transaction failed!");  // Set the status
      // ... other UI updates
    }
  }, [collectionId, nftId, listingPrice, endTimeUnix, useKdaFee, selectedKda]); // Include useKdaFee and selectedKda in dependencies
  

  const claimNft = useCallback(async (order) => {
    const payload = {
      claimType: 2, // Assuming this is the correct claim type
      id: orderId,
    };
  
    const contract = {
      type: 9, // Type for claiming an NFT
      payload: payload
    };
  
    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }
  
    try {
      const unsignedTx = await web.buildTransaction([contract], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Claim NFT transaction response:', response);
  
      // Handle the UI updates and state changes as needed
      setTransactionStatus("Claim transaction completed!");  // Set the status
      // ... other UI updates
  
    } catch (error) {
      console.error('Error in claim NFT transaction:', error);
      setTransactionStatus("Claim transaction failed!");  // Set the status
      // ... other UI updates
    }
  }, [orderId, useKdaFee, selectedKda]); // Include useKdaFee and selectedKda in dependencies
  
  const buyNft = useCallback(async (orderId, price) => {
    const payload = {
      buyType: 1, // Assuming this is the correct buy type
      id: orderId, // The ID of the order to buy
      currencyId: "KLV", // The currency ID
      amount: price * 10 ** 6, 
    };
  
    const contract = {
      type: 17, // The transaction type for buying an NFT
      payload: payload
    };
  
    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }
  
    try {
      const unsignedTx = await web.buildTransaction([contract], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Buy NFT transaction response:', response);
  
      // Handle the UI updates and state changes as needed
      setTransactionStatus("Buy transaction completed!");  // Set the success status
      // ... other UI updates
  
    } catch (error) {
      console.error('Error in buy NFT transaction:', error);
      setTransactionStatus("Buy transaction failed!");  // Set the failure status
      // ... other UI updates
    }
  }, [orderId, useKdaFee, selectedKda]); // Include useKdaFee and selectedKda in dependencies
  

  const cancelOrder = useCallback(async () => {
    const payload = {
      claimType: 2, // Set the appropriate claim type for cancellation
      orderId: orderId, // Use the orderId for the order to be cancelled
    };
  
    const contract = {
      type: 19, // Transaction type for cancelling an order
      payload: payload
    };
  
    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }
  
    try {
      const unsignedTx = await web.buildTransaction([contract], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Cancel order transaction response:', response);
  
      // Handle the UI updates and state changes as needed
      setTransactionStatus("Order cancellation completed!");  // Set the status
      // ... other UI updates
  
    } catch (error) {
      console.error('Error in cancel order transaction:', error);
      setTransactionStatus("Order cancellation failed!");  // Set the status
      // ... other UI updates
    }
  }, [orderId, useKdaFee, selectedKda]); // Include useKdaFee and selectedKda in dependencies
  
  
  

  const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="confirmation-modal">
        <div className="modal-content-confirm">
          <h2>{title}</h2>
          {children}
          <div className="modal-actions">
            <button onClick={onConfirm} className="confirm-button">Confirm</button>
            <button onClick={onClose} className="cancel-button">Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  const checkNftOwnership = async () => {
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/assets/nft/holder/${encodeURIComponent(collectionId)}/${encodeURIComponent(nftId)}`);
      const ownerAddress = response.data.data.account.address;
      setNftOwner(ownerAddress); // Set the owner address
    } catch (error) {
      console.error('Error checking NFT ownership:', error);
    }
  };
  
  const fetchMetadata = async () => {
    try {
      // Fetching data from both pages
      const [assetResponsePage1, assetResponsePage2] = await Promise.all([
        axios.get('https://api.mainnet.klever.finance/v1.0/assets/list?page=1&limit=100'),
        axios.get('https://api.mainnet.klever.finance/v1.0/assets/list?page=2&limit=100')
      ]);
      
      // Merging assets from both pages
      const assets = [
        ...assetResponsePage1.data.data.assets,
        ...assetResponsePage2.data.data.assets
      ];
  
      // Find the matching asset based on collectionId
      const matchingAsset = assets.find(asset => asset.assetId === collectionId);
  
      if (!matchingAsset) {
        console.error('Matching asset not found');
        return;
      }
  
      const metadataObject = matchingAsset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
      let metadataUri = metadataObject ? metadataObject.value : null;
  
      // Handling for Love Monsters collection
      if (metadataUri && metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
        metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nftId}.json`;
      } 
      // Handling for other collections
      else if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) {
          metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        metadataUri = `${metadataUri}/${nftId}`;
      }
  
      if (!metadataUri) {
        console.error('Metadata URI not found for', nftId);
        return null;
      }
  
      try {
        const metadataResponse = await axios.get(metadataUri);
        const metadata = metadataResponse.data;
  
        // Replace image URL for Love Monsters collection
        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
  
        return metadata;
      } catch (error) {
        console.error('Primary fetch failed, trying fallback for', nftId);
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftId}`;
        const fallbackResponse = await axios.get(fallbackUri);
        return fallbackResponse.data;
      }
    } catch (error) {
      console.error(`Error fetching metadata for nonce ${nftId}:`, error);
      return null;
    }
  };
  
  
  
  const fetchNftData = async () => {
    try {
      const metadata = await fetchMetadata();
      if (metadata) {
        setNft(metadata);
      }
  
      let allHistory = [];
      const totalPages = 3; // Assuming max 3 pages, adjust as necessary
  
      for (let page = 1; page <= totalPages; page++) {
        const url = `https://api.mainnet.klever.finance/marketplaces/nfts/activity?nftId=${collectionId}/${nftId}&sort=desc&limit=10&page=${page}`;
        const response = await axios.get(url);
        const historyData = response.data.data.history;
        allHistory = allHistory.concat(historyData);
      }
  
      setHistory(allHistory);
  
      // Process the latest transaction after all history data is fetched
      if (allHistory && allHistory.length > 0) {
        const latestTransaction = allHistory[0];
        const assetId = `${collectionId}/${nftId}`;
  
        // Find the matching contract for the current asset
        const matchingContract = latestTransaction.contract.find(
          (contract) => contract.typeString === "SellContractType" && contract.parameter.assetId === assetId
        );
  
        if (matchingContract) {
          const endTimeFromContract = matchingContract.parameter.endTime;
  
          if (endTimeFromContract) {
            setEndTime(endTimeFromContract);
          }
  
          // Find the corresponding receipt that matches the orderId and assetId
          let latestOrderId;
          latestTransaction.receipts.forEach((receipt) => {
            if (receipt.assetId === assetId && receipt.typeString === "KAppTransfer") {
              latestOrderId = receipt.orderId;
            }
          });
  
          if (latestOrderId) {
            setOrderId(latestOrderId);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching NFT data:', error);
    }
  };
  
  useEffect(() => {
    if (history && history.length > 0) {
      const { isSale, price } = checkLatestTransaction();
      if (isSale) {
        setLatestPrice(price);
      }
    }
  }, [history]);
  
  useEffect(() => {
    fetchNftData();
    checkNftOwnership();
  }, [collectionId, nftId]);
  
  const goBack = () => navigate(-1);
  

// Function to check the latest transaction and find the sale price and sender if it's a sale
const checkLatestTransaction = () => {
  const latestTransaction = history?.[0]; // Get the latest transaction

  if (latestTransaction) {
    const isSale = latestTransaction.contract.some(contract => contract.typeString === "SellContractType");
    
    if (isSale) {
      const saleContract = latestTransaction.contract.find(contract => contract.typeString === "SellContractType");
      const price = saleContract.parameter.price / 1000000;
      const sender = latestTransaction.sender; // Correctly extract sender
      return { isSale, price, sender };
    }
  }

  return { isSale: false, price: null, sender: null };
};

const openModal = (title, content) => {
  setModalTitle(title);
  setModalContent(content);
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
  setModalTitle("");
  setModalContent(null);
  setTransactionStatus("");

  // Reset the price confirmation state and the listing price
  setIsPriceConfirmed(false);
  setListingPrice(""); // Or setTempPrice(""); if you're using a temporary state for the price
};


const { isSale, sender } = checkLatestTransaction();

// Modify existing handlers to open modal with appropriate content
const handleBuy = () => {
  openModal("Buy NFT", <p>Are you sure you want to buy {collectionId}/{nftId} for {latestPrice} KLV?</p>);
};

const handleCancel = () => {
  openModal("Cancel Listing", <p>Are you sure you want to cancel {collectionId}/{nftId} from sale?</p>);
};

const handleSell = () => {
  openModal(`Sell ${collectionId}/${nftId} ?`);
};
const handleClaim = () => {
  openModal("Claim NFT", <p>Are you sure you want to claim {collectionId}/{nftId}?</p>);
};


const handleTransactionConfirmation = useCallback(async () => {
  setTransactionStatus("Processing transaction...");

  if (modalTitle.includes('Sell')) {
    try {
      await listNftForSale(); // Handle selling the NFT
      setTransactionStatus("Transaction successful!"); // Set a success transaction status
      setTimeout(closeModal, 2000); // Close the modal after showing success message
    } catch (error) {
      setTransactionStatus("Transaction failed!"); // Set a failed transaction status
      setTimeout(closeModal, 2000); // Close the modal after showing the failed message
    }
  } else if (modalTitle.includes('Claim')) {
    try {
      await claimNft(); // Handle claiming the NFT
      setTransactionStatus("Claim successful!"); // Set a success transaction status
      setTimeout(closeModal, 2000); // Close the modal after showing success message
    } catch (error) {
      setTransactionStatus("Claim transaction failed!"); // Set a failed transaction status
      setTimeout(closeModal, 2000); // Close the modal after showing the failed message
    }
  } else if (modalTitle.includes('Buy')) {
    try {
      await buyNft(orderId, latestPrice);
      setTransactionStatus("Buy transaction successful!");
      setTimeout(closeModal, 2000);
    } catch (error) {
      setTransactionStatus("Buy transaction failed!");
      setTimeout(closeModal, 2000);
    }
  } else if (modalTitle.includes('Cancel Listing')) {
    try {
      await cancelOrder(); // Call the cancelOrder function
      setTransactionStatus("Order cancellation successful!");
      setTimeout(closeModal, 2000);
    } catch (error) {
      setTransactionStatus("Order cancellation failed!");
      setTimeout(closeModal, 2000);
    }
  }
}, [modalTitle, listNftForSale, claimNft, buyNft, cancelOrder, closeModal]); // Add cancelOrder to the dependency array



const handleDateChange = (e) => {
  const newDate = e.target.value;
  setEndDate(newDate);
  const unixTimestamp = Math.floor(new Date(newDate).getTime() / 1000);
  setEndTimeUnix(unixTimestamp);
};

const priceInputRef = useRef();

const confirmPrice = () => {
  const newPrice = priceInputRef.current.value;
  if (!isNaN(newPrice) && newPrice.trim() !== '') {
    setListingPrice(newPrice);
    console.log("Confirmed price:", newPrice); // Log the price
    setIsPriceConfirmed(true); // Update the state to reflect that the price is confirmed
  }
};



const isClaimable = () => {
  return endTime && new Date().getTime() / 1000 > endTime;
};

  
  if (!nft) {
    return <div>Loading NFT data...</div>;
  }
  return (
  <div className="nft-details-container">
    <button onClick={goBack} className="back-to-marketplace">← Back</button>
    <div className="nft-details">
      <div className="nft-content">
        <div className="nft-image-container">
          <img src={nft.image} alt={`NFT ${collectionId}`} className="nft-image" />
        </div>
        {history && (
          <div className="nft-history">
            <h2>History</h2>
              <div className="history-list">
                {history.map((item, index) => {
                  const sellContract = item.contract.find(contract => contract.typeString === "SellContractType");
                  const marketBuyContract = item.contract.find(contract => contract.typeString === "BuyContractType" && contract.parameter.buyType === "MarketBuy");
                  const cancelContract = item.contract.find(contract => contract.typeString === "CancelMarketOrderContractType");
                  const itoBuyContract = item.contract.find(contract => contract.typeString === "BuyContractType" && contract.parameter.buyType === "ITOBuy");
                  const assetTriggerContractType = item.contract.find(contract => contract.typeString === "AssetTriggerContractType" && contract.parameter.triggerType === "Mint");
                  const ClaimContract = item.contract.find(contract => contract.typeString === "ClaimContractType");
                  const TransferContractType = item.contract.find(contract => contract.typeString === "TransferContractType");

                  return (
                    <div key={index} className="history-item">
                      <p className="history-date">{new Date(item.timestamp).toLocaleDateString()} {new Date(item.timestamp).toLocaleTimeString()}</p>
                      {sellContract && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Listed for {(sellContract.parameter.price / 1000000).toLocaleString('en-US')} KLV</p>
                      )}
                      {marketBuyContract && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Purchased for {(marketBuyContract.parameter.amount / 1000000).toLocaleString('en-US')} KLV</p>
                      )}
                      {cancelContract && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Unlisted the NFT</p>
                      )}
                      {itoBuyContract && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Minted</p>
                      )}
                      {assetTriggerContractType && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Minted</p>
                      )}
                      {ClaimContract && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Claimed the NFT</p>
                      )}
                      {TransferContractType && (
                        <p className="history-action">{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} Transferred to klv1...{TransferContractType.parameter.toAddress.slice(-4)}</p>
                      )}
                      {/* Add additional logic for other contract types as needed */}
                    </div>
                  );
                  
                })}
              </div>
            </div>
          )}
        </div>
        <div className="nft-info">
        <h1 className="nft-title">{nft.name}</h1>
        <p className="nft-description">{nft.description}</p>
        <ul className="nft-traits">
          {nft.attributes && nft.attributes.map((trait, index) => (
            <li key={index} className="nft-trait">
              <strong>{trait.trait_type}:</strong> {trait.value}
            </li>
          ))}
        </ul>
        <div className="nft-purchase">
  {/* Display the price only if the NFT is not expired and is for sale */}
  {!isClaimable() && latestPrice && (
    <p className="latest-price">
      <span className="sale-text"></span> {latestPrice.toLocaleString("en-US")} KLV
    </p>
  )}

  {isConnected ? (
    <>
      {/* Buy button: Displayed if the NFT is for sale and not expired */}
      {isSale && nftOwner === ExchangeAddress && sender !== walletAddress && !isClaimable() && (
        <button id="buyButton" className="buy-now-button" onClick={handleBuy}>Buy</button>
      )}

      {/* Cancel button: Displayed if the NFT is for sale, not expired, and the wallet is the seller */}
      {isSale && nftOwner === ExchangeAddress && sender === walletAddress && !isClaimable() && (
        <button id="cancelButton" className="cancel-now-button" onClick={handleCancel}>Cancel</button>
      )}

      {/* Sell button: Displayed if the wallet owns the NFT and it's not for sale */}
      {!isSale && nftOwner === walletAddress && (
        <button id="sellButton" className="sell-now-button" onClick={handleSell}>Sell</button>
      )}

      {/* Claim button: Displayed if the NFT is expired and the wallet is the seller */}
      {isSale && nftOwner === ExchangeAddress && sender === walletAddress && isClaimable() && (
        <button id="claimButton" className="claim-now-button" onClick={handleClaim}>Claim</button>
      )}

      {/* Expired text: Displayed if the NFT is expired and the wallet is not the seller */}
      {isClaimable() && nftOwner !== walletAddress && (
        <p className="expired-text">EXPIRED</p>
      )}
    </>
  ) : (
    <p className="connect-wallet-details">Connect your wallet first!</p>
  )}
</div>

      </div>
    </div>

    <ConfirmationModal 
  isOpen={isModalOpen} 
  onClose={closeModal} 
  onConfirm={handleTransactionConfirmation}
  title={modalTitle}
>
  {modalTitle.includes('Sell') && (
    <>
      {!isPriceConfirmed ? (
        <>
          <p>Set your selling price:</p>
          <input
            ref={priceInputRef}
            type="text"
            className="price-input-field"
            placeholder="Enter Price in KLV"
            onBlur={confirmPrice}
          />
        </>
      ) : (
        <p className="confirmed-price">Price: {listingPrice} KLV</p>
      )}
      <p>Set end date for your listing:</p>
      <input
        type="date"
        className="modal-select"
        value={endDate}
        onChange={handleDateChange}
      />
    </>
  )}

  {modalTitle.includes('Claim') && (
    <p>Are you sure you want to claim {collectionId}/{nftId}?</p>
  )}
   {modalTitle.includes('Buy') && (
    <p>Are you sure you want to buy {collectionId}/{nftId} for {latestPrice} KLV?</p>
  )}
  {modalTitle.includes('Cancel Listing') && (
    <p>Are you sure you want to cancel the listing of {collectionId}/{nftId}?</p>
  )}

  {transactionStatus && <p className="transaction-status">{transactionStatus}</p>}
</ConfirmationModal>

  </div>
);
}
export default NftDetailsPage;
