import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LaunchpadsOverview.css';

const LaunchpadsOverview = () => {
    const initialLaunchpads = [
        {
            id: 1,
            name: 'DRAGON',
            ticker: 'DRG-17KE',
            logo: 'https://i.ibb.co/1ZWb4kX/dragon.png',
            targetAmount: 600000000,
            currentAmount: 0,
            price: 0.05,
            address: 'klv1scc9g9zkaqpcqyd36t9x79epdyeqwerqhhf6qsykeza96y99uwcsq303w6',
            precision: 6,
            startTime: '2024-10-27T19:00:00Z',
            endTime: '2025-05-31T12:00:00Z'
        },
        {
            id: 2,
            name: 'DaxDoge',
            ticker: 'DAXDO-1A4L',
            logo: 'https://daxdoge.uwu.ai/assets/images/image01.jpg?v=912ed6fd?w=2048',
            targetAmount: 1000000000,
            currentAmount: 0,
            price: 0.006,
            address: 'klv1m9aqercc32hg79y8j2m90jjykxk727cjsdh5y2mdrauqxjx0mg6sp973h2',
            precision: 8,
            startTime: '2024-11-25T10:00:00Z',
            endTime: '2025-12-25T10:00:00Z'
        }
    ];
    const [launchpads, setLaunchpads] = useState(initialLaunchpads);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const fetchBalance = async (launchpad) => {
        const url = `https://api.mainnet.klever.finance/v1.0/address/${launchpad.address}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            const assetData = data.data.account.assets[launchpad.ticker];
            if (assetData) {
                const balance = parseInt((assetData.balance / Math.pow(10, launchpad.precision)).toFixed(0), 10);
                setLaunchpads(launchpads => launchpads.map(lp => {
                    if (lp.id === launchpad.id) {
                        return { ...lp, currentAmount: balance };
                    }
                    return lp;
                }));
            }
        } catch (error) {
            console.error(`Error fetching balance for ${launchpad.ticker}:`, error);
        }
    };

    useEffect(() => {
        const fetchBalances = () => {
            initialLaunchpads.forEach(launchpad => {
                fetchBalance(launchpad);
            });
        };

        fetchBalances();
        const interval = setInterval(fetchBalances, 10000); // Refresh every 10 seconds
        setIsLoading(false);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="launchpads-overview-container">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    {launchpads.length === 0 ? ( // Check if launchpads array is empty
                        <p>No active launchpads at the moment. Please check back later!</p>
                    ) : (
                        <div className="launchpads-grid">
                            {launchpads.map((launchpad) => (
                                <div key={launchpad.id} className={`launchpad-item ${new Date() < new Date(launchpad.startTime) ? 'upcoming' : 'live'}`}>
                                    <div className="launchpad-info">
                                        <img src={launchpad.logo} alt={`${launchpad.name} logo`} className="launchpad-logo" />
                                        <div className="launchpad-details">
                                            <span className="launchpad-name">{launchpad.name}</span>
                                            <span className="launchpad-ticker">{launchpad.ticker}</span>
                                            <span className="launchpad-price">{`${launchpad.price} KLV`}</span>
                                            <div className="launchpad-dates">
                                                <span className="launch-date">Start: {new Date(launchpad.startTime).toUTCString()}</span>
                                                <span className="launch-date">End: {new Date(launchpad.endTime).toUTCString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="participate-btn" onClick={() => navigate(`/launchpad/${launchpad.id}-${launchpad.ticker.toLowerCase()}`)}>
                                        Participate
                                    </button>
                                    <div className="progress-bar-background">
                                        <div className="progress-bar-fill" style={{
                                            width: `${(100 * (1 - launchpad.currentAmount / launchpad.targetAmount)).toFixed(2)}%`
                                        }}></div>
                                        <span className="progress-numbers-overlay">
                                            {`${(launchpad.targetAmount - launchpad.currentAmount).toLocaleString()}/${launchpad.targetAmount.toLocaleString()} (${(100 * (1 - launchpad.currentAmount / launchpad.targetAmount)).toFixed(2)}%)`}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            <p className="disclaimer">We do not promote any projects listed. Please make responsible and informed decisions when investing.</p>
        </div>
    );
};

export default LaunchpadsOverview;
